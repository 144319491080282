import { RiDeleteBinLine } from "react-icons/ri";
import { Button } from "../../../../composables";
import { FoodItemCounter } from "../../../_component";
import { MealsEntity } from "Redux/reducer/cart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { removeFromCart } from "Redux/actions/cart";
import { getNairaEquivalent } from "utils/helper";
import { useState } from "react";
export function OrderCard({ item }: { item: MealsEntity }) {
  // removeFromCart
  const dispatch = useDispatch();
  const { currentOrderIndex } = useSelector((state: RootState) => state.cart);
  const [imageError, setImageError] = useState(!item?.image?.url);

  // Generate placeholder URL using UI Avatars
  const getPlaceholderImage = () => {
    const name = encodeURIComponent(item.name || 'Meal');
    // Parameters:
    // - background=random: gives a consistent color based on text
    // - bold=true: makes text bold
    // - format=svg: get vector format for better quality
    return `https://ui-avatars.com/api/?name=${name}&format=svg&size=128`;
  };
  return (
    <div className="  w-full flex items-start gap-x-2  border-b py-3 justify-start">
      <div className="w-[90px] h-[75px] rounded-[22px]  overflow-hidden">
        <img
          src={imageError ? getPlaceholderImage() : item?.image?.url}
          onError={() => setImageError(true)} alt="food"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-full flex flex-col items-start gap-y-2">
        <div className="flex w-full items-start  text-start  flex-row justify-between gap-y-0">
          <p className="flex flex-wrap w-4/5 text-xs items-start justify-start">
            {item.name}
          </p>

          <Button
            onClick={() =>
              dispatch(
                removeFromCart(currentOrderIndex as string, item.product)
              )
            }
            className="h-8 rounded-full bg-[#FF4444]/20 w-8 "
          >
            <RiDeleteBinLine className="text-lg text-[#FF4444]" />
          </Button>
        </div>

        <div className="w-full flex items-center justify-between">
          <p className="font-semibold text-base">₦{getNairaEquivalent(item.mealPrice)}</p>
          <FoodItemCounter item={item} />
        </div>
      </div>
    </div>
  );
}
