import { useState } from "react";
import { IoIosStar } from "react-icons/io";
import { Heart } from "iconsax-react";
import { toast } from "react-toastify";
import { Button } from "../../../composables";
import { cn } from "../../../../lib";
import { MealItemsEntity } from "types/global.interface";
import { useAddToFavoriteMeal } from "utils/api";
import { errorLogger, getNairaEquivalent } from "utils/helper";
import { Success } from "assets/icon";

export function MealCard({
  className,
  meal,
  onClick,
}: {
  className?: string;
  meal: MealItemsEntity;
  onClick: () => void;
}) {
  const [imageError, setImageError] = useState(!meal?.image?.url);
  const [isLiked, setIsLiked] = useState(false);
  const { mutateAsync } = useAddToFavoriteMeal();

  // Generate placeholder URL using UI Avatars
  const getPlaceholderImage = () => {
    const name = encodeURIComponent(meal.name || 'Meal');
    // Parameters:
    // - background=random: gives a consistent color based on text
    // - bold=true: makes text bold
    // - format=svg: get vector format for better quality
    return `https://ui-avatars.com/api/?name=${name}&format=svg&size=128`;
  };

  const handleMealFav = (mealId: string) => {
    try {
      mutateAsync(mealId, {
        onSuccess: () => {
          toast.success("Meal added to favorites", {
            icon: Success,
          });
          setIsLiked(true);
        },
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  return (
    <div
      onClick={onClick}
      className="flex rounded-t-xl rounded-b-3xl w-full p-2 border h-fit flex-col gap-y-1"
    >
      <div
        className={cn(
          "w-full rounded-xl h-[130px] relative overflow-hidden",
          className
        )}
      >
        <img
          src={imageError ? getPlaceholderImage() : meal?.image?.url}
          onError={() => setImageError(true)}
          alt={meal?.name ?? "Meal image"}
          className="w-full h-full rounded-t-xl rounded-b-2xl object-cover"
        />
        <div className="bg-white absolute top-3 right-3 rounded-3xl px-2 py-1 flex items-center justify-center gap-x-1">
          <IoIosStar className="text-lg text-yellow-500" />
          <span>4.5</span>
          <span className="text-gray-600">(0)</span>
        </div>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleMealFav(meal._id);
          }}
          className="h-7 absolute bottom-3 right-3 w-7 rounded-full bg-white"
        >
          <Heart
            className={`${isLiked ? "text-red-500 fill-red-500" : ""}`}
            size={16}
          />
        </Button>
      </div>
      <div className="py-1 gap-y-1 px-2 flex flex-col items-start justify-start whitespace-nowrap overflow-scroll">
        <p>{meal?.name ?? ""}</p>
        <p className="font-semibold text-[15px]">
          ₦{getNairaEquivalent(meal?.unitPrice) ?? ""}
        </p>
      </div>
    </div>
  );
}