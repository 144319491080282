import { ArrowLeft, Minus } from "iconsax-react";

import { useNavigate } from "react-router";

import { Add } from "iconsax-react";
import { Button } from "components/composables";
import AddRoute from "./add_route";
import { useState } from "react";
import { useDeleteRoute, useFetchMyRoute } from "utils/api";
import { RouteOptimizationSkeleton } from "../../../composables/Skeletons/routeoptimization";
import { toast } from "react-toastify";
import emptyBox from "assets/Svg/emptyBox.svg";
import { Success } from "assets/icon";

const RouteOptimization = () => {
  const { data, isLoading } = useFetchMyRoute();
  const navigate = useNavigate();
  const { mutateAsync } = useDeleteRoute();

  const [isAddRoute, setIsAddRoute] = useState(false);
  return (
    <main className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Route Optimization</h1>
      </div>

      <div className="mt-6">
        {!isLoading ? (
          <div>
            {data && data?.length > 0 && <p>Preferred Route</p>}
            {data?.map((v, key) => (
              <div key={key} className="relative mt-4">
                <div className="bg-[#F4F4F4] w-full flex flex-col rounded-[10px] mt-4 px-4 py-3 !h-auto">
                  <span className="text-[#999999] text-xs">From</span>
                  <div className="p-0 mt-0 bg-transparent w-full outline-none border-none">
                    {v.from}
                  </div>
                </div>
                <button
                  onClick={() => {
                    mutateAsync({ routeId: v._id });
                    toast.success("Route removed successfully", {
                      icon: Success,
                    });
                  }}
                  className="size-6 bg-primary-100/50 p-3 grid place-content-center rounded-full absolute top-[54px] right-6"
                >
                  <Minus size="20" color="#578735" />
                </button>
                <div className="bg-[#F4F4F4] w-full flex flex-col rounded-[10px] mt-3 px-4 py-3 !h-auto">
                  <span className="text-[#999999] text-xs">To</span>
                  <div className="p-0 mt-0 bg-transparent w-full outline-none border-none">
                    {v.to}
                  </div>
                </div>
                <div className="bg-[#F4F4F4] w-full flex items-center rounded-[10px] mt-4 px-4 py-3 !h-auto">
                  <div className="text-black text-xs w-[60px]">Price ₦</div>
                  <div className="p-0 mt-0 bg-transparent w-full outline-none border-none">
                    {v.amount}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <RouteOptimizationSkeleton />
        )}
        <div>
          {data?.length === 0 && !isLoading && (
            <div className="py-20 flex flex-col mx-auto text-center justify-center items-center w-[350px]">
              <img src={emptyBox} alt="" />
              <h2 className="mt-5 font-semibold text-lg">
                Click the + button below to add new route
              </h2>
            </div>
          )}
        </div>
      </div>
      <div className="fixed bottom-24 right-5 z-10">
        <Button
          onClick={() => setIsAddRoute(true)}
          className="p-5 flex rounded-xl bg-[#7BBE4A] shadow-[-1px_8px_10px_0px_#00000029] text-white"
        >
          <Add />
        </Button>
      </div>
      {isAddRoute && <AddRoute setIsAddRoute={setIsAddRoute} />}
    </main>
  );
};

export default RouteOptimization;
