export const ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
export const SECRET_ACCESS_KEY = "vuL5pS0oIPrK5ZgRw/U749IP22hI8zv9EdjYXjUd";
export const MEAL_TYPE = {
  FOOD_STACK: "food_stack",
  MEAL_PACK: "meal_pack",
  PRE_ORDER: "pre_order",
  MEAL_KIT: "meal_kit",
  HARVEST_PACK: "harvest_pack",
};

export const JekaEat_FSC2024 = "JekaEat_FSC2024";
export const ReadyToCook24 = "ReadyToCook24";
export const Tech_Week = "Tech week";
export const Sauced_Expo = "Sauced_Expo";
export const fbAdsAddAppiD = "3397200843905556";
export const Jekaeat_50 = "Jekaeat_50";
export const Jekaeat_30 = "Jekaeat_30";
export const Jekaeat_20 = "Jekaeat_20";
export const Jekaeat_free = "jekaeat_free";
export const SERVICE_CHARGE = 0;
export const COMMISSION_CHARGE = 0.02;
export const isProd = process.env.NODE_ENV === "production";
export const isLocal = process.env.NODE_ENV === "development";
export const VendorCategory = {
  Restaurant: "restaurant",
  FreshFood: "fresh_food",
};
export interface IDiscount {
  code: string;
  discount: number;
  active: boolean;
}
export const deliveryDiscount: IDiscount[] = [
  {
    code: Jekaeat_20,
    discount: 0.2,
    active: true,
  },
  {
    code: Jekaeat_30,
    discount: 0.3,
    active: false,
  },
  {
    code: Jekaeat_50,
    discount: 0.5,
    active: false,
  },
  {
    code: Jekaeat_free,
    discount: 0.5,
    active: false,
  },
];
