import { ArrowLeft } from "iconsax-react";
import { useState } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

import { FaSpinner } from "react-icons/fa6";

import { Button } from "components/composables";
import { useAddRoute } from "utils/api";
import { errorLogger } from "utils/helper";
import { toast } from "react-toastify";
import { Success } from "assets/icon";
import { Error } from "assets/icon";

const libraries: ("places" | "drawing" | "geometry" | "visualization")[] = ["places"];

const AddRoute = ({
  setIsAddRoute,
}: {
  setIsAddRoute: (v: boolean) => void;
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  });
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [price, setPrice] = useState("");
  // Autocomplete instances for both inputs
  const [fromAutocomplete, setFromAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [toAutocomplete, setToAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const handlePlaceChanged = (type: "from" | "to") => {
    let selectedPlace: string | undefined;

    if (type === "from" && fromAutocomplete) {
      selectedPlace = fromAutocomplete.getPlace()?.formatted_address;
      setFrom(selectedPlace || "");
    } else if (type === "to" && toAutocomplete) {
      selectedPlace = toAutocomplete.getPlace()?.formatted_address;
      setTo(selectedPlace || "");
    }
  };
  const { mutateAsync, isLoading } = useAddRoute();
  const handleSubmit = async () => {
    if (!from || !to || !price) {
      toast.error("all fields are required", {
        icon: Error,
      });
      return;
    }
    const payload = {
      from,
      to,
      amount: price,
    };

    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Route added successfully", { icon: Success });
          setIsAddRoute(false);
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <main className="p-5 fixed inset-0 bg-white z-50">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            setIsAddRoute(false);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Back</h1>
      </div>

      <div className="mt-6">
        <div className=" mt-4">
          <div className="bg-[#F4F4F4] w-full flex flex-col rounded-[10px] mt-4 px-4 py-3 !h-auto">
            <span className="text-[#999999] text-xs">From</span>

            <Autocomplete
              onLoad={(autocomplete) => {
                // Set the Autocomplete instance and apply restrictions
                autocomplete.setComponentRestrictions({ country: "ng" }); // Restrict to Nigeria
                setFromAutocomplete(autocomplete);
              }}
              onPlaceChanged={() => handlePlaceChanged("from")}

            >
              <input
                type="text"
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                className="p-0 mt-0 bg-transparent w-full outline-none border-none"
              />
            </Autocomplete>

          </div>

          <div className="bg-[#F4F4F4] w-full flex flex-col rounded-[10px] mt-3 px-4 py-3 !h-auto">
            <span className="text-[#999999] text-xs">To</span>
            <Autocomplete
              onLoad={(autocomplete) => {
                // Set the Autocomplete instance and apply restrictions
                autocomplete.setComponentRestrictions({ country: "ng" }); // Restrict to Nigeria
                setToAutocomplete(autocomplete);
              }}
              onPlaceChanged={() => handlePlaceChanged("to")}

            >
              <input
                type="text"
                onChange={(e) => {
                  setTo(e.target.value);
                }}
                className="p-0 mt-0 bg-transparent w-full outline-none border-none"
              />
            </Autocomplete>
          </div>
          <div className="bg-[#F4F4F4] w-full flex items-center rounded-[10px] mt-4 px-4 py-3 !h-auto">
            <div className="text-black text-xs w-[60px]">Price ₦</div>
            <input
              type="number"
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              className="p-0 mt-0 bg-transparent w-full outline-none border-none"
            />
          </div>
        </div>
        <Button
          onClick={handleSubmit}
          className="bg-primary-100 text-center p-3 text-white w-full mt-4"
        >
          {isLoading ? (
            <FaSpinner className="animate animate-spin" />
          ) : (
            "Update"
          )}
        </Button>
      </div>
    </main>
  );
};

export default AddRoute;
